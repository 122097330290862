/**@jsx jsx */
import { jsx } from "theme-ui";

export function HelpDesk() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <g style={{ mixBlendMode: "multiply" }}>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29"
              fill="#1787FC"
            />
          </g>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29V29Z"
              stroke="#1787FC"
              strokeWidth="1.21"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <path
        d="M52.8808 29.4467C52.8796 34.0825 51.5037 38.6138 48.9273 42.4677C46.3509 46.3217 42.6896 49.3251 38.4063 51.0983C34.123 52.8715 29.4101 53.3348 24.8636 52.4296C20.317 51.5244 16.1409 49.2915 12.8633 46.013C9.58575 42.7346 7.35389 38.5579 6.44995 34.0111C5.54601 29.4642 6.01057 24.7515 7.78491 20.4687C9.55924 16.1859 12.5637 12.5254 16.4183 9.94998C20.2729 7.37459 24.8046 6 29.4404 6C32.5192 6 35.5678 6.60651 38.4121 7.78489C41.2564 8.96327 43.8408 10.6904 46.0175 12.8678C48.1942 15.0451 49.9207 17.6299 51.0983 20.4745C52.2759 23.3191 52.8816 26.3679 52.8808 29.4467Z"
        fill="white"
      />
      <path
        d="M42.3841 27.6582C41.6199 27.1658 40.7333 26.9055 39.8201 26.9055H38.5494C38.0549 26.9055 37.6541 27.3064 37.6541 27.8008V37.3122C37.6541 37.8066 38.0549 38.2075 38.5494 38.2075H39.8201C42.4341 38.2075 44.5606 36.0809 44.5606 33.467V31.6459C44.5606 30.0264 43.7468 28.5356 42.3841 27.6582Z"
        fill="#1787FC"
      />
      <path
        d="M39.62 39.9819H39.3944C38.4207 41.9136 36.4185 43.2418 34.1116 43.2418C33.6172 43.2418 33.2163 43.6427 33.2163 44.1371C33.2163 44.6316 33.6172 45.0325 34.1116 45.0325C37.5255 45.0325 40.4273 42.7998 41.4349 39.7182C40.8593 39.8895 40.2503 39.9819 39.62 39.9819Z"
        fill="#1787FC"
      />
      <path
        d="M20.0112 26.9055H18.7405C17.8273 26.9055 16.9407 27.1657 16.1769 27.658C14.8138 28.5356 14 30.0264 14 31.6459V33.467C14 36.0809 16.1266 38.2075 18.7405 38.2075H20.0112C20.5057 38.2075 20.9065 37.8066 20.9065 37.3122V27.8008C20.9065 27.3064 20.5057 26.9055 20.0112 26.9055Z"
        fill="#1787FC"
      />
      <path
        d="M29.2805 15C23.076 15 17.9109 19.5318 16.9192 25.459C17.5248 25.2566 18.1607 25.1468 18.8077 25.1334C19.9012 20.3613 24.1815 16.7907 29.2805 16.7907C34.3794 16.7907 38.6598 20.3613 39.7532 25.1335C40.4002 25.1469 41.0361 25.2567 41.6417 25.4591C40.6501 19.5318 35.485 15 29.2805 15Z"
        fill="#1787FC"
      />
    </svg>
  );
}
