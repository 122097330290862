/**@jsx jsx */
import { jsx } from "theme-ui";

export function LeftNavigation() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="23.9999"
        cy="24"
        r="22.6493"
        transform="rotate(-179.144 23.9999 24)"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="14.7808"
        y2="-1"
        transform="matrix(-0.8 0.6 -0.8 -0.6 26.0142 15.2847)"
        stroke="#767F97"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="14.7808"
        y2="-1"
        transform="matrix(0.8 0.6 -0.8 0.6 14.1896 24.1532)"
        stroke="#767F97"
        strokeWidth="2"
      />
    </svg>
  );
}
