/**@jsx jsx */
import { jsx } from "theme-ui";

export const Atom = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="currentcolor"
    >
      <path d="M3.102,20.898c0.698,0.699,1.696,1.068,2.887,1.068c1.742,0,3.855-0.778,6.012-2.127c2.156,1.35,4.27,2.127,6.012,2.127 c1.19,0,2.188-0.369,2.887-1.068c1.269-1.269,1.411-3.413,0.401-6.039c-0.358-0.932-0.854-1.895-1.457-2.859 c0.604-0.964,1.099-1.927,1.457-2.859c1.01-2.626,0.867-4.771-0.401-6.039c-0.698-0.699-1.696-1.068-2.887-1.068 c-1.742,0-3.855,0.778-6.012,2.127c-2.156-1.35-4.27-2.127-6.012-2.127c-1.19,0-2.188,0.369-2.887,1.068 C1.833,4.371,1.69,6.515,2.7,9.141C3.059,10.073,3.554,11.036,4.157,12c-0.604,0.964-1.099,1.927-1.457,2.859 C1.69,17.485,1.833,19.629,3.102,20.898z M19.433,15.577c0.689,1.79,0.708,3.251,0.052,3.907c-0.32,0.32-0.815,0.482-1.473,0.482 c-1.167,0-2.646-0.503-4.208-1.38c0.862-0.666,1.718-1.412,2.544-2.238c0.82-0.82,1.569-1.677,2.239-2.546 C18.923,14.403,19.21,14.998,19.433,15.577z M12,17.417c-0.998-0.715-1.994-1.543-2.934-2.483c-0.945-0.946-1.783-1.937-2.5-2.934 c0.717-0.997,1.555-1.988,2.5-2.934C10.006,8.127,11.002,7.299,12,6.583c0.998,0.715,1.994,1.543,2.934,2.483 c0.945,0.946,1.783,1.937,2.5,2.934c-0.717,0.997-1.555,1.988-2.5,2.934C13.994,15.873,12.998,16.701,12,17.417z M18.012,4.034 c0.657,0,1.152,0.162,1.473,0.482c0.656,0.656,0.638,2.117-0.052,3.907c-0.223,0.579-0.51,1.174-0.846,1.775 c-0.67-0.869-1.419-1.726-2.239-2.546c-0.826-0.826-1.682-1.572-2.544-2.238C15.366,4.538,16.845,4.034,18.012,4.034z M4.567,8.423 c-0.689-1.79-0.708-3.251-0.052-3.907c0.32-0.32,0.815-0.482,1.473-0.482c1.167,0,2.646,0.503,4.208,1.38 C9.334,6.08,8.479,6.826,7.652,7.652c-0.82,0.82-1.569,1.677-2.239,2.546C5.077,9.597,4.79,9.002,4.567,8.423z M4.567,15.577 c0.223-0.579,0.51-1.174,0.846-1.775c0.67,0.869,1.419,1.726,2.239,2.546c0.826,0.826,1.682,1.572,2.544,2.238 c-1.563,0.877-3.041,1.38-4.208,1.38c-0.657,0-1.152-0.162-1.473-0.482C3.859,18.828,3.878,17.367,4.567,15.577z"></path>
      <circle cx="12" cy="12" r="2.574"></circle>
    </svg>
  );
};
