/**@jsx jsx */
import { jsx } from "theme-ui";

export function LogoIcon() {
  return (
    <div sx={{ width: "48px", height: "48px" }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM24 41.472C33.6495 41.472 41.472 33.6495 41.472 24C41.472 14.3505 33.6495 6.528 24 6.528C14.3505 6.528 6.528 14.3505 6.528 24C6.528 33.6495 14.3505 41.472 24 41.472Z"
          fill="currentcolor"
        />
        <rect
          x="15.36"
          y="23.808"
          width="8.832"
          height="8.832"
          fill="currentcolor"
        />
        <path
          d="M23.808 15.36H28.224C30.6629 15.36 32.64 17.3371 32.64 19.776C32.64 22.2149 30.6629 24.192 28.224 24.192H23.808V15.36Z"
          fill="currentcolor"
        />
      </svg>
    </div>
  );
}
