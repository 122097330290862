/**@jsx jsx */
import { Button, jsx } from "theme-ui";
import { Link, navigate } from "gatsby";

import { HamburguerMenu } from "./hamburger-menu";
import { Logo } from "../../../icons";
import { Nav } from "../../navigation/nav-bar";
import { useEffect, useState } from "react";

export function Header() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      setLoggedIn(!!localStorage.getItem("token"));
    }
  }, []);

  return (
    <header
      sx={{
        height: "64px",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        bg: "header",
        color: "background",
        py: 2,

        px: [2, null, null, 4],
        zIndex: 300,
      }}
    >
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Link to="/">
          <Logo />
        </Link>
        <HamburguerMenu />
        <Nav />
        <Button
          variant="header"
          onClick={() => navigate("/app/")}
          sx={{
            px: [3],
            ml: [3],
            display: ["none", null, null, "block"],
            transition: ["0.4s"],
            ":hover": {
              color: ["text"],
              bg: ["background"],
            },
          }}
        >
          {isLoggedIn ? "Mi Cuenta" : "Ingresar"}
        </Button>
      </div>
    </header>
  );
}
