/**@jsx jsx */
import { Fragment, useState, useContext, Component } from "react";
import { jsx } from "theme-ui";

import { AppContext } from "../../context/app-context";
import { LinkType } from "../../types/link";
import { Link, navigate } from "gatsby";
import { DownArrow, RightArrow } from "../../icons";

interface DrawerProps {}

export function DrawerLinks(props: DrawerProps) {
  const [showFirst, setShowFirst] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [showId, setShowId] = useState(null);
  const [secondId, setSecondId] = useState(null);

  const {
    links,
    locale,
    setMenuOpen,
    setCategoryId,
    setServiceId,
  } = useContext(AppContext);

  const linkLocale = locale === "es-PE" ? "es" : "en";
  const handleClick = (parent, child, service: string) => {
    const serviceBoolean = service === "service";
    if (serviceBoolean) {
      setCategoryId("1");
      setServiceId(child.id);
      navigate(parent);
      setMenuOpen(false);
    } else {
      setCategoryId(child.id);
      setServiceId(child.services[0].id);
      navigate(parent);
      setMenuOpen(false);
    }
  };

  return (
    <nav
      sx={{
        height: "calc(90vh - 64px)",
        width: "90vw",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridAutoRows: "max-content",
        textAlign: "left",
        gap: "24px",
      }}
    >
      {links.map((firstLink: LinkType) => {
        const hasChildren = firstLink.page !== null;
        if (hasChildren) {
          return (
            <Fragment key={firstLink.id}>
              <div>
                <div
                  onClick={() => {
                    setShowId(firstLink.id);
                    setShowFirst((c) => !c);
                  }}
                  sx={{
                    color: "background",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    fontWeight: "heading",
                    fontFamily: "heading",
                    fontSize: 3,
                    display: "grid",
                    gridTemplateColumns: "1fr 20px",
                    borderBottom: "1px solid white",
                  }}
                >
                  {firstLink.title}

                  {showFirst && showId === firstLink.id ? (
                    <RightArrow />
                  ) : (
                    <DownArrow />
                  )}
                </div>
              </div>
              {showFirst && showId === firstLink.id && (
                <div
                  sx={{
                    px: 4,
                    display: "grid",
                    gap: 3,
                    borderLeft: "2px solid white",
                  }}
                >
                  {firstLink.page.map((secondLink: LinkType) => {
                    const hasChildren =
                      secondLink.service_category?.length > 0 ||
                      secondLink.services?.length > 0;
                    if (hasChildren) {
                      return (
                        <Fragment key={secondLink.id}>
                          <div
                            onClick={() => {
                              setShowSecond(true);
                              setSecondId((c) =>
                                c && c === secondLink.id ? null : secondLink.id
                              );
                            }}
                            sx={{
                              color: "background",
                              textDecoration: "none",
                              textTransform: "uppercase",
                              fontWeight: "heading",
                              fontFamily: "heading",
                              fontSize: 2,
                            }}
                          >
                            <div
                              onClick={() =>
                                showSecond && secondId === secondLink.id
                              }
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 15px",
                              }}
                            >
                              {secondLink.title}
                              {showSecond && secondId === secondLink.id ? (
                                <RightArrow />
                              ) : (
                                <DownArrow />
                              )}
                            </div>
                          </div>

                          <div
                            sx={{
                              px: 4,
                              borderLeft: "2px solid white",
                              display:
                                showSecond && secondId === secondLink.id
                                  ? "grid"
                                  : "none",
                              gap: 3,
                            }}
                          >
                            {secondLink.services &&
                              secondLink.services !== null &&
                              secondLink.services.map((thirdLink) => (
                                <div
                                  key={thirdLink.id}
                                  onClick={() =>
                                    handleClick(
                                      `/${linkLocale}/${firstLink.slug}/${secondLink.slug}`,
                                      thirdLink,
                                      "service"
                                    )
                                  }
                                  sx={{
                                    color: "background",
                                    textDecoration: "none",
                                    textTransform: "uppercase",
                                    fontWeight: "heading",
                                    fontFamily: "heading",
                                    fontSize: 2,
                                  }}
                                >
                                  {thirdLink.title}
                                </div>
                              ))}
                            {secondLink.service_category &&
                              secondLink.service_category !== null &&
                              secondLink.service_category.map((thirdLink) => (
                                <div
                                  key={thirdLink.id}
                                  onClick={() =>
                                    handleClick(
                                      `/${linkLocale}/${firstLink.slug}/${secondLink.slug}`,
                                      thirdLink,
                                      "category"
                                    )
                                  }
                                  sx={{
                                    color: "background",
                                    textDecoration: "none",
                                    textTransform: "uppercase",
                                    fontWeight: "heading",
                                    fontFamily: "heading",
                                    fontSize: 2,
                                  }}
                                >
                                  {thirdLink.title}
                                </div>
                              ))}
                          </div>
                        </Fragment>
                      );
                    }
                    return (
                      <Link
                        key={secondLink.id}
                        to={`/${linkLocale}/${firstLink.slug}/${secondLink.slug}`}
                        onClick={() => setMenuOpen(false)}
                        sx={{
                          color: "background",
                          textDecoration: "none",
                          textTransform: "uppercase",
                          fontWeight: "heading",
                          fontFamily: "heading",
                          fontSize: 2,
                        }}
                      >
                        {secondLink.title}
                      </Link>
                    );
                  })}
                </div>
              )}
            </Fragment>
          );
        }
        const isExternal = firstLink.slug.match(/^http/);
        const Component = isExternal ? "a" : Link;
        const path = isExternal
          ? firstLink.slug
          : firstLink.slug.includes("home") || firstLink.slug.includes("inicio")
          ? `/${linkLocale}/`
          : `/${linkLocale}/${firstLink.slug}`;
        return (
          <Component
            to={path}
            href={firstLink.slug}
            key={firstLink.id}
            sx={{
              color: "background",
              textDecoration: "none",
              textTransform: "uppercase",
              fontWeight: "heading",
              fontFamily: "heading",
              fontSize: 3,
              borderBottom: "1px solid white",
            }}
            onClick={() => setMenuOpen(false)}
          >
            {firstLink.title}
          </Component>
        );
      })}
    </nav>
  );
}
