/**@jsx jsx */
import { jsx } from "theme-ui";

export function Abap() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <g style={{ mixBlendMode: "multiply" }}>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29"
              fill="#1787FC"
            />
          </g>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29V29Z"
              stroke="#1787FC"
              strokeWidth="1.21"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <path
        d="M52.8808 29.4467C52.8796 34.0825 51.5037 38.6138 48.9273 42.4677C46.3509 46.3217 42.6896 49.3251 38.4063 51.0983C34.123 52.8715 29.4101 53.3348 24.8636 52.4296C20.317 51.5244 16.1409 49.2915 12.8633 46.013C9.58575 42.7346 7.35389 38.5579 6.44995 34.0111C5.54601 29.4642 6.01057 24.7515 7.78491 20.4687C9.55924 16.1859 12.5637 12.5254 16.4183 9.94998C20.2729 7.37459 24.8046 6 29.4404 6C32.5192 6 35.5678 6.60651 38.4121 7.78489C41.2564 8.96327 43.8408 10.6904 46.0175 12.8678C48.1942 15.0451 49.9207 17.6299 51.0983 20.4745C52.2759 23.3191 52.8816 26.3679 52.8808 29.4467Z"
        fill="white"
      />
      <path
        d="M32.0821 25.9138C31.7504 25.5801 31.3826 25.2844 30.9856 25.0319C29.9341 24.3586 28.7116 24.0007 27.4629 24.0007C25.7298 23.9975 24.0671 24.6861 22.8437 25.9138L16.9088 31.8533C15.6875 33.077 15.0011 34.7349 15 36.4637C14.9977 40.0713 17.9203 42.9977 21.528 43C23.2584 43.006 24.9196 42.3207 26.1425 41.0963L31.0416 36.1972C31.1306 36.109 31.1803 35.9886 31.1796 35.8632C31.1782 35.6055 30.968 35.3979 30.7103 35.3994H30.5237C29.5001 35.403 28.4857 35.2064 27.5376 34.8208C27.3629 34.7489 27.1621 34.7895 27.029 34.9234L23.5063 38.4508C22.4124 39.5447 20.6389 39.5447 19.545 38.4508C18.4511 37.3569 18.4511 35.5834 19.545 34.4895L25.5033 28.5359C26.5963 27.4443 28.367 27.4443 29.46 28.5359C30.1966 29.2292 31.3456 29.2292 32.0821 28.5359C32.399 28.2187 32.5912 27.7981 32.6234 27.3508C32.6575 26.8163 32.4603 26.2929 32.0821 25.9138Z"
        fill="#1787FC"
      />
      <path
        d="M41.0825 16.9133C38.5314 14.3622 34.3952 14.3622 31.8441 16.9133L26.9496 21.8031C26.8152 21.9382 26.7765 22.1413 26.8517 22.3164C26.926 22.4919 27.0996 22.6046 27.2902 22.601H27.4629C28.4852 22.5991 29.498 22.7972 30.4443 23.1842C30.619 23.2561 30.8198 23.2155 30.9529 23.0816L34.4662 19.5728C35.5601 18.479 37.3337 18.479 38.4275 19.5728C39.5214 20.6667 39.5214 22.4403 38.4275 23.5341L34.051 27.906L34.0136 27.948L32.4832 29.4691C31.3902 30.5607 29.6196 30.5607 28.5266 29.4691C27.79 28.7758 26.641 28.7758 25.9044 29.4691C25.5855 29.7887 25.3932 30.213 25.3632 30.6635C25.3291 31.198 25.5262 31.7214 25.9044 32.1006C26.4445 32.643 27.0763 33.0852 27.7707 33.407C27.8687 33.4537 27.9667 33.491 28.0647 33.533C28.1627 33.575 28.2653 33.6076 28.3633 33.645C28.4613 33.6823 28.5639 33.715 28.6619 33.743L28.9372 33.8176C29.1238 33.8642 29.3105 33.9016 29.5017 33.9342C29.7321 33.9684 29.9642 33.9903 30.197 33.9996H30.5235H30.5515L30.8315 33.9669C30.9341 33.9623 31.0415 33.9389 31.1628 33.9389H31.3214L31.6433 33.8923L31.7927 33.8643L32.0633 33.8083H32.1146C33.2607 33.5204 34.3072 32.927 35.1427 32.0913L41.0823 26.1517C43.6336 23.6006 43.6336 19.4644 41.0825 16.9133Z"
        fill="#1787FC"
      />
    </svg>
  );
}
