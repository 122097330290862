/**@jsx jsx */
import { jsx } from "theme-ui";

export function SapBusiness() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <g style={{ mixBlendMode: "multiply" }}>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29"
              fill="#1787FC"
            />
          </g>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29V29Z"
              stroke="#1787FC"
              strokeWidth="1.21"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <path
        d="M52.8808 29.4467C52.8796 34.0825 51.5037 38.6138 48.9273 42.4677C46.3509 46.3217 42.6896 49.3251 38.4063 51.0983C34.123 52.8715 29.4101 53.3348 24.8636 52.4296C20.317 51.5244 16.1409 49.2915 12.8633 46.013C9.58575 42.7346 7.35389 38.5579 6.44995 34.0111C5.54601 29.4642 6.01057 24.7515 7.78491 20.4687C9.55924 16.1859 12.5637 12.5254 16.4183 9.94998C20.2729 7.37459 24.8046 6 29.4404 6C32.5192 6 35.5678 6.60651 38.4121 7.78489C41.2564 8.96327 43.8408 10.6904 46.0175 12.8678C48.1942 15.0451 49.9207 17.6299 51.0983 20.4745C52.2759 23.3191 52.8816 26.3679 52.8808 29.4467Z"
        fill="white"
      />
      <path
        d="M21.1689 14.2101C23.7029 12.7329 26.5832 11.97 29.509 12.0009C32.4347 12.0319 35.2986 12.8556 37.8014 14.3859L35.6855 17.9135C33.8084 16.7657 31.6605 16.1479 29.4662 16.1247C27.2719 16.1015 25.1116 16.6737 23.2112 17.7816L21.1689 14.2101Z"
        fill="#1787FC"
      />
      <path
        d="M43.4869 20.248C44.9499 22.8065 45.7055 25.7147 45.6749 28.6688C45.6442 31.6229 44.8285 34.5145 43.3127 37.0414L39.819 34.9051C40.9558 33.0098 41.5677 30.8412 41.5906 28.6256C41.6136 26.41 41.0469 24.2289 39.9497 22.31L43.4869 20.248Z"
        fill="#1787FC"
      />
      <path
        d="M37.5068 42.782C34.9729 44.2591 32.0926 45.0221 29.1668 44.9912C26.241 44.9602 23.3772 44.1365 20.8744 42.6061L22.9903 39.0786C24.8674 40.2264 27.0152 40.8442 29.2096 40.8674C31.4039 40.8906 33.5642 40.3184 35.4646 39.2105L37.5068 42.782Z"
        fill="#1787FC"
      />
      <path
        d="M15.1889 36.744C13.7259 34.1856 12.9703 31.2774 13.0009 28.3233C13.0315 25.3692 13.8473 22.4776 15.363 19.9506L18.8568 22.087C17.72 23.9822 17.1081 26.1509 17.0851 28.3665C17.0622 30.5821 17.6289 32.7632 18.7261 34.682L15.1889 36.744Z"
        fill="#1787FC"
      />
      <path
        d="M19.5896 34.1281L19.4534 40.2129L14.3025 37.0514L19.5896 34.1281Z"
        fill="#1787FC"
      />
      <path
        d="M20.6372 13.2689L23.6512 18.5398H17.6232L20.6372 13.2689Z"
        fill="#1787FC"
      />
      <path
        d="M39.5057 17.5638L44.7261 20.6069L39.5057 23.6501L39.5057 17.5638Z"
        fill="#1787FC"
      />
      <path
        d="M40.7839 38.4872L37.7171 43.7269L34.7562 38.4254L40.7839 38.4872Z"
        fill="#1787FC"
      />
      <path
        d="M35.0713 28.6597C35.0713 31.7882 32.5633 34.2996 29.4999 34.2996C26.4365 34.2996 23.9285 31.7882 23.9285 28.6597C23.9285 25.5312 26.4365 23.0198 29.4999 23.0198C32.5633 23.0198 35.0713 25.5312 35.0713 28.6597Z"
        stroke="#1787FC"
        strokeWidth="3"
      />
    </svg>
  );
}
