/**@jsx jsx */
import { jsx } from "theme-ui";

export function SapS4() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <g style={{ mixBlendMode: "multiply" }}>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29"
              fill="#1787FC"
            />
          </g>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29V29Z"
              stroke="#1787FC"
              strokeWidth="1.21"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <path
        d="M52.8808 29.4467C52.8796 34.0825 51.5037 38.6138 48.9273 42.4677C46.3509 46.3217 42.6896 49.3251 38.4063 51.0983C34.123 52.8715 29.4101 53.3348 24.8636 52.4296C20.317 51.5244 16.1409 49.2915 12.8633 46.013C9.58575 42.7346 7.35389 38.5579 6.44995 34.0111C5.54601 29.4642 6.01057 24.7515 7.78491 20.4687C9.55924 16.1859 12.5637 12.5254 16.4183 9.94998C20.2729 7.37459 24.8046 6 29.4404 6C32.5192 6 35.5678 6.60651 38.4121 7.78489C41.2564 8.96327 43.8408 10.6904 46.0175 12.8678C48.1942 15.0451 49.9207 17.6299 51.0983 20.4745C52.2759 23.3191 52.8816 26.3679 52.8808 29.4467Z"
        fill="white"
      />
      <path
        d="M20.3662 32.6071C20.3662 31.69 21.1097 30.9464 22.0269 30.9464V30.9464C22.9441 30.9464 23.6876 31.69 23.6876 32.6071V34.2679H20.3662V32.6071Z"
        fill="#1787FC"
      />
      <rect
        x="25.2673"
        y="30.7034"
        width="3.56446"
        height="3.56446"
        fill="#1787FC"
      />
      <path
        d="M25.2673 28.9212C25.2673 27.9369 26.0653 27.1389 27.0496 27.1389V27.1389C28.0339 27.1389 28.8318 27.9369 28.8318 28.9212V30.7034H25.2673V28.9212Z"
        fill="#1787FC"
      />
      <rect
        x="30.1687"
        y="30.7034"
        width="3.56446"
        height="3.56446"
        fill="#1787FC"
      />
      <rect
        x="30.1687"
        y="27.1389"
        width="3.56446"
        height="3.56446"
        fill="#1787FC"
      />
      <path
        d="M30.1689 25.3567C30.1689 24.3724 30.9669 23.5745 31.9512 23.5745V23.5745C32.9355 23.5745 33.7334 24.3724 33.7334 25.3567V27.1389H30.1689V25.3567Z"
        fill="#1787FC"
      />
      <rect
        x="35.0696"
        y="30.7034"
        width="3.56446"
        height="3.56446"
        fill="#1787FC"
      />
      <rect
        x="35.0696"
        y="27.1389"
        width="3.56446"
        height="3.56446"
        fill="#1787FC"
      />
      <rect
        x="35.0696"
        y="23.5745"
        width="3.56446"
        height="3.56446"
        fill="#1787FC"
      />
      <path
        d="M35.0696 21.7922C35.0696 20.8079 35.8675 20.01 36.8518 20.01V20.01C37.8361 20.01 38.634 20.8079 38.634 21.7922V23.5745H35.0696V21.7922Z"
        fill="#1787FC"
      />
      <path
        d="M44.3946 36.3424H43.781V17.6707C43.781 16.7495 43.0593 16 42.1722 16H16.8278C15.9408 16 15.2191 16.7495 15.2191 17.6707V36.3425H14.6055C14.2711 36.3425 14 36.624 14 36.9712V39.6462C14 40.9559 15.026 42.0213 16.2872 42.0213H42.7128C43.974 42.0213 45 40.9558 45 39.6462V36.9712C45.0001 36.6239 44.729 36.3424 44.3946 36.3424V36.3424ZM16.43 17.6707C16.43 17.4428 16.6085 17.2575 16.8278 17.2575H42.1722C42.3916 17.2575 42.5701 17.4429 42.5701 17.6707V36.3425H16.43V17.6707ZM43.7891 39.6461C43.7891 40.2624 43.3063 40.7637 42.7129 40.7637H16.2872C15.6937 40.7637 15.2109 40.2624 15.2109 39.6461V37.5999H43.7891V39.6461Z"
        fill="#1787FC"
      />
      <path
        d="M31.2932 38.5531H27.7068C27.3724 38.5531 27.1013 38.8346 27.1013 39.1819C27.1013 39.5291 27.3724 39.8106 27.7068 39.8106H31.2932C31.6276 39.8106 31.8987 39.5291 31.8987 39.1819C31.8987 38.8346 31.6276 38.5531 31.2932 38.5531Z"
        fill="#AFB5C3"
      />
    </svg>
  );
}
