/**@jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { LogoIcon } from "../../../icons/logo-icon";
import { ContactLinks } from "./contact-links";
import { SocialLinks } from "./social-links";
import { Nav } from "../../navigation/nav-bar";

export function Footer() {
  return (
    <footer
      sx={{
        bg: "footer",
        width: "100%",
        height: [64, null, null, 112],
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: ["1fr 64px 1fr"],
        gridTemplateRows: ["1fr ", null, null, "1fr 1fr"],
        p: 2,
      }}
    >
      <Nav footer />
      <ContactLinks />
      <Link
        to="/"
        sx={{ color: "primary", display: "flex", justifyContent: "center" }}
      >
        <LogoIcon />
      </Link>
      <SocialLinks />
    </footer>
  );
}
