/**@jsx jsx */

import { jsx } from "theme-ui";

export const Building = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="currentcolor"
    >
      <path d="M18,2H6C4.897,2,4,2.897,4,4v17c0,0.552,0.447,1,1,1h14c0.553,0,1-0.448,1-1V4C20,2.897,19.103,2,18,2z M18,20H6V4h12V20z"></path>
      <path d="M8 6H11V8H8zM13 6H16V8H13zM8 10H11V12H8zM13 10.031H16V12H13zM8 14H11V16H8zM13 14H16V16H13z"></path>
    </svg>
  );
};
