/**@jsx jsx */
import { jsx } from "theme-ui";

export function Logo() {
  return (
    <div
      sx={{
        width: ["160px", "235px"],
        display: "flex",
        alignItems: "center",
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 235 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM24 41.472C33.6495 41.472 41.472 33.6495 41.472 24C41.472 14.3505 33.6495 6.528 24 6.528C14.3505 6.528 6.528 14.3505 6.528 24C6.528 33.6495 14.3505 41.472 24 41.472Z"
          fill="#4F84F6"
        />
        <rect
          x="15.36"
          y="23.808"
          width="8.832"
          height="8.832"
          fill="#4F84F6"
        />
        <path
          d="M23.808 15.36H28.224C30.6629 15.36 32.64 17.3371 32.64 19.776C32.64 22.2149 30.6629 24.192 28.224 24.192H23.808V15.36Z"
          fill="#4F84F6"
        />
        <path
          d="M76.7299 14.6948C78.3381 14.6948 79.7732 15.1141 81.035 15.9527C82.2969 16.7914 83.2866 17.963 84.0041 19.4676C84.7216 20.9723 85.0804 22.6989 85.0804 24.6475C85.0804 26.5714 84.7216 28.2734 84.0041 29.7533C83.3113 31.2086 82.334 32.3309 81.0722 33.1202C79.8103 33.9096 78.3629 34.3042 76.7299 34.3042C75.4928 34.3042 74.367 34.0699 73.3526 33.6012C72.3381 33.1079 71.5588 32.4296 71.0144 31.5663V42H64.2969V20.6146C64.2969 18.592 64.1979 16.7914 64 15.2127H70.3093L70.6804 18.1357C71.1505 17.0997 71.9299 16.2734 73.0186 15.6567C74.1072 15.0154 75.3443 14.6948 76.7299 14.6948ZM74.6887 29.3094C75.901 29.3094 76.8165 28.9147 77.4351 28.1254C78.0536 27.3361 78.3629 26.1768 78.3629 24.6475C78.3629 23.0935 78.0412 21.8972 77.3979 21.0586C76.7794 20.1953 75.8763 19.7636 74.6887 19.7636C73.4763 19.7636 72.5485 20.1706 71.9052 20.9846C71.2619 21.7986 70.9402 22.9949 70.9402 24.5735C70.9402 26.1028 71.2619 27.2744 71.9052 28.0884C72.5485 28.9024 73.4763 29.3094 74.6887 29.3094Z"
          fill="white"
        />
        <path
          d="M100.131 14.7688C101.047 14.7688 101.752 14.9044 102.247 15.1757V21.2806C101.183 20.7873 100.156 20.5406 99.1661 20.5406C96.3455 20.5406 94.9352 21.8849 94.9352 24.5735V34.0082H88.2177V20.6146C88.2177 18.592 88.1187 16.7914 87.9207 15.2127H94.23L94.6383 18.3577C95.0836 17.1984 95.8012 16.3104 96.7909 15.6937C97.7805 15.0771 98.8939 14.7688 100.131 14.7688Z"
          fill="white"
        />
        <path
          d="M123.658 15.2127V34.0082H117.052V31.4553C116.508 32.3433 115.716 33.0462 114.677 33.5642C113.637 34.0576 112.499 34.3042 111.262 34.3042C109.654 34.3042 108.219 33.9096 106.957 33.1202C105.695 32.3309 104.705 31.2086 103.988 29.7533C103.295 28.2734 102.949 26.5714 102.949 24.6475C102.949 22.6989 103.308 20.9723 104.025 19.4676C104.743 17.963 105.732 16.7914 106.994 15.9527C108.256 15.1141 109.679 14.6948 111.262 14.6948C112.499 14.6948 113.625 14.9661 114.639 15.5087C115.679 16.0267 116.483 16.7297 117.052 17.6177V15.2127H123.658ZM113.341 29.3094C114.528 29.3094 115.444 28.9024 116.087 28.0884C116.73 27.2744 117.052 26.1028 117.052 24.5735C117.052 23.0195 116.73 21.8356 116.087 21.0216C115.444 20.1829 114.528 19.7636 113.341 19.7636C112.178 19.7636 111.262 20.1953 110.594 21.0586C109.926 21.8972 109.592 23.0935 109.592 24.6475C109.592 26.1521 109.914 27.3114 110.557 28.1254C111.2 28.9147 112.128 29.3094 113.341 29.3094Z"
          fill="white"
        />
        <path
          d="M148.708 15.2127H155.426V34.0082H148.708V15.2127ZM155.537 6V12.0308H148.523V6H155.537Z"
          fill="white"
        />
        <path
          d="M167.044 34.3042C163.284 34.3042 160.315 33.5519 158.137 32.0473L159.844 27.4964C160.859 28.1871 162.009 28.7297 163.296 29.1244C164.583 29.4943 165.844 29.6793 167.082 29.6793C167.849 29.6793 168.442 29.5683 168.863 29.3464C169.284 29.1244 169.494 28.816 169.494 28.4214C169.494 28.0761 169.333 27.8047 169.012 27.6074C168.715 27.3854 168.133 27.1881 167.267 27.0154L164.224 26.3124C162.294 25.9178 160.896 25.3011 160.03 24.4625C159.189 23.5992 158.768 22.4152 158.768 20.9106C158.768 19.702 159.127 18.629 159.844 17.6917C160.562 16.7544 161.552 16.0267 162.814 15.5087C164.1 14.9661 165.572 14.6948 167.23 14.6948C168.591 14.6948 169.927 14.8921 171.238 15.2867C172.55 15.6814 173.774 16.2364 174.913 16.9517L173.131 21.3916C172.141 20.7503 171.127 20.2569 170.088 19.9116C169.049 19.5663 168.071 19.3936 167.156 19.3936C166.414 19.3936 165.832 19.517 165.411 19.7636C164.991 19.9856 164.781 20.3186 164.781 20.7626C164.781 21.0339 164.904 21.2559 165.152 21.4286C165.399 21.5766 165.869 21.7369 166.562 21.9096L169.717 22.6125C171.721 23.0812 173.156 23.7718 174.022 24.6845C174.913 25.5725 175.358 26.7934 175.358 28.3474C175.358 30.1973 174.616 31.6526 173.131 32.7133C171.671 33.7739 169.642 34.3042 167.044 34.3042Z"
          fill="white"
        />
        <path
          d="M190.865 14.6948C193.092 14.6948 194.75 15.3731 195.838 16.7297C196.952 18.0617 197.509 20.0843 197.509 22.7975V34.0082H190.791V23.0935C190.791 21.9342 190.593 21.1079 190.197 20.6146C189.801 20.0966 189.183 19.8376 188.341 19.8376C187.327 19.8376 186.511 20.1829 185.892 20.8736C185.273 21.5396 184.964 22.4275 184.964 23.5375V34.0082H178.247V20.6146C178.247 18.592 178.148 16.7914 177.95 15.2127H184.259L184.593 17.9877C185.311 16.927 186.201 16.1131 187.265 15.5457C188.329 14.9784 189.529 14.6948 190.865 14.6948Z"
          fill="white"
        />
        <path
          d="M218.812 25.2765H206.973C207.097 26.7318 207.518 27.7801 208.235 28.4214C208.953 29.0627 210.004 29.3834 211.39 29.3834C212.33 29.3834 213.27 29.2354 214.21 28.9394C215.151 28.6187 216.004 28.1871 216.771 27.6444L218.478 32.0843C217.538 32.7749 216.4 33.3176 215.064 33.7122C213.728 34.1069 212.392 34.3042 211.056 34.3042C208.903 34.3042 207.035 33.9096 205.452 33.1202C203.868 32.3309 202.656 31.2086 201.815 29.7533C200.973 28.2734 200.553 26.5344 200.553 24.5365C200.553 22.6372 200.949 20.9476 201.74 19.4676C202.557 17.963 203.67 16.7914 205.081 15.9527C206.516 15.1141 208.149 14.6948 209.979 14.6948C211.761 14.6948 213.32 15.0894 214.656 15.8787C215.992 16.668 217.019 17.8027 217.736 19.2826C218.454 20.7379 218.812 22.4399 218.812 24.3885V25.2765ZM210.165 19.2826C208.507 19.2826 207.468 20.2446 207.048 22.1686H213.06C212.812 20.2446 211.848 19.2826 210.165 19.2826Z"
          fill="white"
        />
        <path
          d="M232.619 28.9024C233.065 28.9024 233.658 28.8284 234.401 28.6804V33.7492C233.485 34.1192 232.322 34.3042 230.912 34.3042C228.364 34.3042 226.421 33.5889 225.085 32.1583C223.774 30.7276 223.118 28.742 223.118 26.2014V20.1336H219.592V15.2127H223.118V10.9579L229.836 8.81192V15.2127H234.364V20.1336H229.836V26.2014C229.836 27.0894 230.096 27.7677 230.615 28.2364C231.159 28.6804 231.827 28.9024 232.619 28.9024Z"
          fill="white"
        />
        <path
          d="M76.7299 14.6948C78.3381 14.6948 79.7732 15.1141 81.035 15.9527C82.2969 16.7914 83.2866 17.963 84.0041 19.4676C84.7216 20.9723 85.0804 22.6989 85.0804 24.6475C85.0804 26.5714 84.7216 28.2734 84.0041 29.7533C83.3113 31.2086 82.334 32.3309 81.0722 33.1202C79.8103 33.9096 78.3629 34.3042 76.7299 34.3042C75.4928 34.3042 74.367 34.0699 73.3526 33.6012C72.3381 33.1079 71.5588 32.4296 71.0144 31.5663V42H64.2969V20.6146C64.2969 18.592 64.1979 16.7914 64 15.2127H70.3093L70.6804 18.1357C71.1505 17.0997 71.9299 16.2734 73.0186 15.6567C74.1072 15.0154 75.3443 14.6948 76.7299 14.6948ZM74.6887 29.3094C75.901 29.3094 76.8165 28.9147 77.4351 28.1254C78.0536 27.3361 78.3629 26.1768 78.3629 24.6475C78.3629 23.0935 78.0412 21.8972 77.3979 21.0586C76.7794 20.1953 75.8763 19.7636 74.6887 19.7636C73.4763 19.7636 72.5485 20.1706 71.9052 20.9846C71.2619 21.7986 70.9402 22.9949 70.9402 24.5735C70.9402 26.1028 71.2619 27.2744 71.9052 28.0884C72.5485 28.9024 73.4763 29.3094 74.6887 29.3094Z"
          fill="white"
        />
        <path
          d="M100.131 14.7688C101.047 14.7688 101.752 14.9044 102.247 15.1757V21.2806C101.183 20.7873 100.156 20.5406 99.1661 20.5406C96.3455 20.5406 94.9352 21.8849 94.9352 24.5735V34.0082H88.2177V20.6146C88.2177 18.592 88.1187 16.7914 87.9207 15.2127H94.23L94.6383 18.3577C95.0836 17.1984 95.8012 16.3104 96.7909 15.6937C97.7805 15.0771 98.8939 14.7688 100.131 14.7688Z"
          fill="white"
        />
        <path
          d="M123.658 15.2127V34.0082H117.052V31.4553C116.508 32.3433 115.716 33.0462 114.677 33.5642C113.637 34.0576 112.499 34.3042 111.262 34.3042C109.654 34.3042 108.219 33.9096 106.957 33.1202C105.695 32.3309 104.705 31.2086 103.988 29.7533C103.295 28.2734 102.949 26.5714 102.949 24.6475C102.949 22.6989 103.308 20.9723 104.025 19.4676C104.743 17.963 105.732 16.7914 106.994 15.9527C108.256 15.1141 109.679 14.6948 111.262 14.6948C112.499 14.6948 113.625 14.9661 114.639 15.5087C115.679 16.0267 116.483 16.7297 117.052 17.6177V15.2127H123.658ZM113.341 29.3094C114.528 29.3094 115.444 28.9024 116.087 28.0884C116.73 27.2744 117.052 26.1028 117.052 24.5735C117.052 23.0195 116.73 21.8356 116.087 21.0216C115.444 20.1829 114.528 19.7636 113.341 19.7636C112.178 19.7636 111.262 20.1953 110.594 21.0586C109.926 21.8972 109.592 23.0935 109.592 24.6475C109.592 26.1521 109.914 27.3114 110.557 28.1254C111.2 28.9147 112.128 29.3094 113.341 29.3094Z"
          fill="white"
        />
        <path
          d="M148.708 15.2127H155.426V34.0082H148.708V15.2127ZM155.537 6V12.0308H148.523V6H155.537Z"
          fill="white"
        />
        <path
          d="M167.044 34.3042C163.284 34.3042 160.315 33.5519 158.137 32.0473L159.844 27.4964C160.859 28.1871 162.009 28.7297 163.296 29.1244C164.583 29.4943 165.844 29.6793 167.082 29.6793C167.849 29.6793 168.442 29.5683 168.863 29.3464C169.284 29.1244 169.494 28.816 169.494 28.4214C169.494 28.0761 169.333 27.8047 169.012 27.6074C168.715 27.3854 168.133 27.1881 167.267 27.0154L164.224 26.3124C162.294 25.9178 160.896 25.3011 160.03 24.4625C159.189 23.5992 158.768 22.4152 158.768 20.9106C158.768 19.702 159.127 18.629 159.844 17.6917C160.562 16.7544 161.552 16.0267 162.814 15.5087C164.1 14.9661 165.572 14.6948 167.23 14.6948C168.591 14.6948 169.927 14.8921 171.238 15.2867C172.55 15.6814 173.774 16.2364 174.913 16.9517L173.131 21.3916C172.141 20.7503 171.127 20.2569 170.088 19.9116C169.049 19.5663 168.071 19.3936 167.156 19.3936C166.414 19.3936 165.832 19.517 165.411 19.7636C164.991 19.9856 164.781 20.3186 164.781 20.7626C164.781 21.0339 164.904 21.2559 165.152 21.4286C165.399 21.5766 165.869 21.7369 166.562 21.9096L169.717 22.6125C171.721 23.0812 173.156 23.7718 174.022 24.6845C174.913 25.5725 175.358 26.7934 175.358 28.3474C175.358 30.1973 174.616 31.6526 173.131 32.7133C171.671 33.7739 169.642 34.3042 167.044 34.3042Z"
          fill="white"
        />
        <path
          d="M190.865 14.6948C193.092 14.6948 194.75 15.3731 195.838 16.7297C196.952 18.0617 197.509 20.0843 197.509 22.7975V34.0082H190.791V23.0935C190.791 21.9342 190.593 21.1079 190.197 20.6146C189.801 20.0966 189.183 19.8376 188.341 19.8376C187.327 19.8376 186.511 20.1829 185.892 20.8736C185.273 21.5396 184.964 22.4275 184.964 23.5375V34.0082H178.247V20.6146C178.247 18.592 178.148 16.7914 177.95 15.2127H184.259L184.593 17.9877C185.311 16.927 186.201 16.1131 187.265 15.5457C188.329 14.9784 189.529 14.6948 190.865 14.6948Z"
          fill="white"
        />
        <path
          d="M218.812 25.2765H206.973C207.097 26.7318 207.518 27.7801 208.235 28.4214C208.953 29.0627 210.004 29.3834 211.39 29.3834C212.33 29.3834 213.27 29.2354 214.21 28.9394C215.151 28.6187 216.004 28.1871 216.771 27.6444L218.478 32.0843C217.538 32.7749 216.4 33.3176 215.064 33.7122C213.728 34.1069 212.392 34.3042 211.056 34.3042C208.903 34.3042 207.035 33.9096 205.452 33.1202C203.868 32.3309 202.656 31.2086 201.815 29.7533C200.973 28.2734 200.553 26.5344 200.553 24.5365C200.553 22.6372 200.949 20.9476 201.74 19.4676C202.557 17.963 203.67 16.7914 205.081 15.9527C206.516 15.1141 208.149 14.6948 209.979 14.6948C211.761 14.6948 213.32 15.0894 214.656 15.8787C215.992 16.668 217.019 17.8027 217.736 19.2826C218.454 20.7379 218.812 22.4399 218.812 24.3885V25.2765ZM210.165 19.2826C208.507 19.2826 207.468 20.2446 207.048 22.1686H213.06C212.812 20.2446 211.848 19.2826 210.165 19.2826Z"
          fill="white"
        />
        <path
          d="M232.619 28.9024C233.065 28.9024 233.658 28.8284 234.401 28.6804V33.7492C233.485 34.1192 232.322 34.3042 230.912 34.3042C228.364 34.3042 226.421 33.5889 225.085 32.1583C223.774 30.7276 223.118 28.742 223.118 26.2014V20.1336H219.592V15.2127H223.118V10.9579L229.836 8.81192V15.2127H234.364V20.1336H229.836V26.2014C229.836 27.0894 230.096 27.7677 230.615 28.2364C231.159 28.6804 231.827 28.9024 232.619 28.9024Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M140.158 34.0082H148.026L139.972 24.3145L147.543 15.2128H139.601L136.15 19.7266L132.661 15.2128H124.793L131.506 23.2834L131.509 23.2786L131.748 23.5735L132.364 24.3145L132.355 24.3253L135.545 28.2734H129.075L124.31 34.0082H132.141L136.15 28.9764L140.158 34.0082Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
