/** @jsx jsx */
import { jsx, Link } from "theme-ui";
import { Link as GatsbyLink, navigate } from "gatsby";
import { Fragment, useContext, useState } from "react";
import { AppContext } from "../../context/app-context";
import { LinkType } from "../../types/link";
import { LocationContext } from "../../context/location-context";

interface NavProps {
  footer?: boolean;
}

export function Nav({ footer = false }: NavProps) {
  const [showFirst, setShowFirst] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [showId, setShowId] = useState<null | string>(null);
  const [secondId, setSecondId] = useState<null | string>(null);
  const { links, locale, setCategoryId, setServiceId } = useContext(AppContext);
  const { serviceRef } = useContext(LocationContext);
  const linkLocale = locale === "es-PE" ? "es" : "en";

  const handleClick = (parent: string, child: any, service: string) => {
    const serviceBoolean = service === "service";
    if (serviceBoolean) {
      setCategoryId("1");
      setServiceId(child.id);
      navigate(parent);
    } else {
      setCategoryId(child.id);
      setServiceId(child.services[0].id);
      navigate(parent);
    }
  };
  return (
    <nav
      sx={{
        gridColumn: [footer ? "1/4" : "auto"],
        justifySelf: [footer ? "center" : "flex-end"],
        display: ["none", null, null, "flex"],
        textAlign: ["left"],
        ml: [!footer && "auto"],
      }}
    >
      {(showFirst || showSecond) && (
        <div
          sx={{
            position: "fixed",
            width: "100vw",
            height: "calc(100vh - 65px)",
            bg: "transparent",
            top: 65,
            left: 0,
            opacity: 0,
            zIndex: -100,
          }}
          onClick={() => {
            setShowFirst(false);
            setShowSecond(false);
            setShowId(null);
          }}
          onMouseOver={() => {
            setShowFirst(false);
            setShowSecond(false);
            setShowId(null);
          }}
        ></div>
      )}
      {links.map((firstLink: LinkType) => {
        const hasChildren = firstLink.page !== null;
        if (!footer && hasChildren) {
          return (
            <Fragment key={firstLink.id}>
              <div
                sx={{
                  fontFamily: ["heading"],
                  p: 2,
                  color: [
                    showId === firstLink.id && showFirst ? "white" : "inherit",
                  ],
                  textTransform: ["uppercase"],
                  textDecoration: ["none"],
                  fontWeight: [800],
                  cursor: ["pointer"],
                  position: ["relative"],
                  bg: [showId === firstLink.id && showFirst && "primary"],
                  borderRadius: ["0 0 8px 8px"],
                }}
                onMouseOver={() => {
                  setShowFirst(true);
                  setShowId(firstLink.id);
                }}
              >
                {firstLink.title}

                <div
                  sx={{
                    position: "absolute",
                    zIndex: 200,
                    display:
                      showId === firstLink.id && showFirst ? "flex" : "none",
                    flexDirection: "column",
                    top: 45,
                    mx: -2,
                    bg: (t) => t.colors.dark[3],
                    borderRadius: "0 0 16px 16px",
                  }}
                >
                  {firstLink.page &&
                    firstLink.page.map((secondLink: LinkType) => {
                      const hasChildren =
                        (secondLink.service_category &&
                          secondLink.service_category?.length > 0) ||
                        (secondLink.services &&
                          secondLink.services?.length > 0);

                      if (hasChildren) {
                        return (
                          <Fragment key={secondLink.id}>
                            <div
                              sx={{
                                fontFamily: ["heading"],
                                color: ["inherit"],
                                textTransform: ["uppercase"],
                                textDecoration: ["none"],
                                fontWeight: [800],
                                cursor: ["pointer"],
                                position: ["relative"],
                                zIndex: [200],
                                borderRadius: ["0 0 8px 8px"],
                                bg: [
                                  secondId === secondLink.id &&
                                    showSecond &&
                                    "primary",
                                ],
                              }}
                              onMouseOver={() => {
                                setSecondId(secondLink.id);
                                setShowSecond(true);
                              }}
                            >
                              <div
                                sx={{
                                  p: 3,
                                }}
                                onClick={() => {
                                  const categoryId =
                                    secondLink.services !== null
                                      ? "1"
                                      : secondLink.service_category &&
                                        secondLink.service_category[0].id;
                                  const serviceId =
                                    secondLink.services !== null
                                      ? secondLink.services &&
                                        secondLink.services[0].id
                                      : secondLink.service_category &&
                                        secondLink.service_category[0]
                                          .services &&
                                        secondLink.service_category[0]
                                          .services[0].id;
                                  setCategoryId(categoryId as string);
                                  setServiceId(serviceId as string);
                                  navigate(
                                    `/${linkLocale}/${firstLink.slug}/${secondLink.slug}/#category`
                                  );
                                }}
                              >
                                {secondLink.title}
                              </div>
                              <div
                                sx={{
                                  display:
                                    secondId === secondLink.id && showSecond
                                      ? "grid"
                                      : "none",
                                  position: "absolute",
                                  placeItems: "center",
                                  zIndex: 300,
                                  minWidth: "160px",
                                  minHeight: "52px",
                                  flexDirection: "column",
                                  letterSpacing: "0.2px",
                                  borderLeft: (t) =>
                                    `3px solid ${t.colors.blue[1]}`,
                                  top: 0,
                                  left: "calc(100%)",
                                  bg: (t) => t.colors.dark[3],
                                  borderRadius: "0 16px 16px 0",
                                }}
                              >
                                {secondLink.services &&
                                  secondLink.services !== null &&
                                  secondLink.services.map((thirdLink) => (
                                    <div
                                      key={thirdLink.id}
                                      onClick={() => {
                                        handleClick(
                                          `/${linkLocale}/${firstLink.slug}/${secondLink.slug}/#category`,
                                          thirdLink,
                                          "service"
                                        );
                                      }}
                                      sx={{
                                        color: "inherit",
                                        textDecoration: "inherit",
                                        py: 2,
                                        px: 3,
                                        zIndex: 200,
                                        fontSize: "12px",
                                        width: "100%",
                                        borderRadius: "0 16px 16px 0",
                                        "&:hover": {
                                          bg: "primary",
                                        },
                                      }}
                                      onMouseOver={() => {
                                        setShowId(null);
                                      }}
                                    >
                                      {thirdLink.title}
                                    </div>
                                  ))}
                                {secondLink.service_category &&
                                  secondLink.service_category !== null &&
                                  secondLink.service_category.map(
                                    (thirdLink) => (
                                      <div
                                        key={thirdLink.id}
                                        onClick={() => {
                                          handleClick(
                                            `/${linkLocale}/${firstLink.slug}/${secondLink.slug}/#category`,
                                            thirdLink,
                                            "category"
                                          );
                                        }}
                                        sx={{
                                          color: "inherit",
                                          textDecoration: "inherit",
                                          py: 2,
                                          px: 3,
                                          borderRadius: "0 16px 16px 0",
                                          zIndex: 200,
                                          width: "100%",
                                          fontSize: "12px",
                                          "&:hover": {
                                            bg: "primary",
                                          },
                                        }}
                                        onMouseOver={() => {
                                          setShowId(null);
                                        }}
                                      >
                                        {thirdLink.title}
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </Fragment>
                        );
                      }
                      const path = `/${linkLocale}/${firstLink.slug}/${secondLink.slug}`;
                      return (
                        <Link
                          as={GatsbyLink}
                          key={secondLink.id}
                          to={path}
                          sx={{
                            color: "inherit",
                            textDecoration: "inherit",
                            zIndex: 400,
                            p: 3,
                            borderRadius: "0 0 8px 8px",

                            "&:hover": {
                              bg: "primary",
                            },
                          }}
                          onMouseOver={() => {
                            setShowId(null);
                          }}
                        >
                          {secondLink.title}
                        </Link>
                      );
                    })}
                </div>
              </div>
            </Fragment>
          );
        }

        const isExternal = firstLink.slug.match(/^http/);
        const Component = isExternal ? "a" : Link;
        const news = linkLocale === "es" ? "ultimas-noticias" : "latest-news";
        const path = isExternal
          ? firstLink.slug
          : firstLink.slug.includes("home") || firstLink.slug.includes("inicio")
          ? `/${linkLocale}/`
          : firstLink.slug.includes("services") ||
            firstLink.slug.includes("servicios")
          ? `/${linkLocale}/#services`
          : firstLink.slug.includes("noticias") ||
            firstLink.slug.includes("news")
          ? `/${linkLocale}/${firstLink.slug}/${news}`
          : `/${linkLocale}/${firstLink.slug}`;
        const props = { [isExternal ? "href" : "to"]: path };
        return (
          <Component
            as={GatsbyLink}
            to={path}
            key={firstLink.id}
            {...props}
            onClick={() => {
              footer &&
                (firstLink.slug.includes("services") ||
                  firstLink.slug.includes("servicios")) &&
                scrollTo(0, (serviceRef as any).current.offsetTop);
            }}
            sx={{
              fontFamily: ["heading"],
              p: [2],
              color: ["inherit"],
              zIndex: [400],
              textTransform: ["uppercase"],
              textDecoration: ["none"],
              fontWeight: [800],
              borderRadius: [!footer && "0 0 8px 8px"],
              "&:hover": {
                bg: [!footer && "primary"],
                color: [footer && "primary"],
              },
            }}
            onMouseEnter={() => setShowFirst(false)}
          >
            {firstLink.title}
          </Component>
        );
      })}
    </nav>
  );
}
