/** @jsx jsx */
import { jsx } from "theme-ui";
import { useContext } from "react";
import { AppContext } from "../../../context/app-context";

interface HamburguerMenuProps {}

export function HamburguerMenu() {
  const { menuOpen, setMenuOpen } = useContext(AppContext);
  return (
    <button
      aria-label="menu"
      sx={{
        display: ["block", null, , "none"],
        border: "none",
        padding: 0,
        width: "48px",
        height: "48px",
        transition: "0.2s",
        position: "relative",
        bg: "transparent",
        outline: "none",
        ml: "auto",
      }}
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
    >
      <span
        sx={{
          width: "32px",
          height: "25px",
          transition: "0.4s",
          display: "block",
          position: "absolute",

          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          margin: "auto",
        }}
      >
        <span
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            margin: "auto",
            width: "32px",
            height: "5px",
            bg: "background",
            transition: "0.4s",
            transform: menuOpen
              ? "rotate(45deg) translate3d(0,0,0)"
              : "rotate(0deg) translate3d(0,-10px,0)",
          }}
        ></span>
        <span
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            margin: "auto",
            width: "32px",
            height: "5px",
            bg: "background",
            transition: "0.4s",
            opacity: menuOpen ? 0 : 1,
          }}
        ></span>
        <span
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            margin: "auto",
            width: "32px",
            height: "5px",
            bg: "background",
            transition: "0.4s",
            transform: menuOpen
              ? "rotate(-45deg) translate3d(0,0,0)"
              : "rotate(0) translate3d(0,10px,0)",
          }}
        ></span>
      </span>
    </button>
  );
}
