/**@jsx jsx */
import { jsx } from "theme-ui";

export function Sapr3() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <g style={{ mixBlendMode: "multiply" }}>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29"
              fill="#1787FC"
            />
          </g>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29V29Z"
              stroke="#1787FC"
              strokeWidth="1.21"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <path
        d="M52.8808 29.4467C52.8796 34.0825 51.5037 38.6138 48.9273 42.4677C46.3509 46.3217 42.6896 49.3251 38.4063 51.0983C34.123 52.8715 29.4101 53.3348 24.8636 52.4296C20.317 51.5244 16.1409 49.2915 12.8633 46.013C9.58575 42.7346 7.35389 38.5579 6.44995 34.0111C5.54601 29.4642 6.01057 24.7515 7.78491 20.4687C9.55924 16.1859 12.5637 12.5254 16.4183 9.94998C20.2729 7.37459 24.8046 6 29.4404 6C32.5192 6 35.5678 6.60651 38.4121 7.78489C41.2564 8.96327 43.8408 10.6904 46.0175 12.8678C48.1942 15.0451 49.9207 17.6299 51.0983 20.4745C52.2759 23.3191 52.8816 26.3679 52.8808 29.4467Z"
        fill="white"
      />
      <path
        d="M22.2856 28.4571H21.7141C21.0829 28.4571 20.5713 28.9688 20.5713 29.6C20.5713 30.2312 21.0829 30.7428 21.7141 30.7428H22.2856C22.9168 30.7428 23.4284 30.2312 23.4284 29.6C23.4284 28.9688 22.9168 28.4571 22.2856 28.4571Z"
        fill="#1787FC"
      />
      <path
        d="M33.5428 23.8857H26.2857C25.6545 23.8857 25.1428 24.3974 25.1428 25.0286C25.1428 25.6598 25.6545 26.1714 26.2857 26.1714H33.5428C34.174 26.1714 34.6857 25.6598 34.6857 25.0286C34.6857 24.3974 34.174 23.8857 33.5428 23.8857Z"
        fill="#1787FC"
      />
      <path
        d="M31.7079 40.9714H20.5714C19.3111 40.9714 18.2857 39.946 18.2857 38.6857V18.5714C18.2857 17.3111 19.3111 16.2857 20.5714 16.2857H34.6222C35.8826 16.2857 36.9079 17.3111 36.9079 18.5714V25.0286C36.9079 25.6598 37.4196 26.1714 38.0508 26.1714C38.682 26.1714 39.1936 25.6598 39.1936 25.0286V18.5714C39.1936 16.0507 37.1429 14 34.6222 14H20.5714C18.0507 14 16 16.0507 16 18.5714V38.6857C16 41.2064 18.0507 43.2571 20.5714 43.2571H31.7079C32.3391 43.2571 32.8508 42.7455 32.8508 42.1143C32.8508 41.4831 32.3391 40.9714 31.7079 40.9714Z"
        fill="#1787FC"
      />
      <path
        d="M22.2856 33.0286H21.7141C21.0829 33.0286 20.5713 33.5402 20.5713 34.1714C20.5713 34.8026 21.0829 35.3143 21.7141 35.3143H22.2856C22.9168 35.3143 23.4284 34.8026 23.4284 34.1714C23.4284 33.5402 22.9168 33.0286 22.2856 33.0286Z"
        fill="#1787FC"
      />
      <path
        d="M33.5428 19.3143H26.2857C25.6545 19.3143 25.1428 19.8259 25.1428 20.4571C25.1428 21.0883 25.6545 21.6 26.2857 21.6H33.5428C34.174 21.6 34.6857 21.0883 34.6857 20.4571C34.6857 19.8259 34.174 19.3143 33.5428 19.3143Z"
        fill="#1787FC"
      />
      <path
        d="M38.7792 34.5688H37.7319C37.2197 34.5688 36.8031 34.1447 36.8031 33.6234V33.4689C36.8031 32.9572 37.2024 32.5714 37.7319 32.5714H38.7222C39.0133 32.5714 39.3143 32.7663 39.3143 33.0923C39.3143 33.7235 39.826 34.2352 40.4572 34.2352C41.0884 34.2352 41.6 33.7235 41.6 33.0923C41.6 31.7227 40.5886 30.5798 39.256 30.3349V29.6C39.256 28.9688 38.7444 28.4571 38.1132 28.4571C37.482 28.4571 36.9703 28.9688 36.9703 29.6V30.3742C36.4047 30.5076 35.8865 30.7896 35.4664 31.2008C34.8544 31.7996 34.5173 32.6052 34.5173 33.4689V33.6234C34.5173 35.405 35.9593 36.8545 37.7319 36.8545H38.7792C39.2948 36.8545 39.7143 37.2722 39.7143 37.7856V38.3285C39.7143 38.8253 39.3123 39.2 38.7792 39.2H37.0772C36.755 39.2 36.512 38.9693 36.512 38.6635C36.512 38.0323 36.0004 37.5206 35.3692 37.5206C34.738 37.5206 34.2263 38.0323 34.2263 38.6635C34.2263 39.4289 34.5263 40.1433 35.0711 40.6752C35.5812 41.1732 36.2522 41.4574 36.9703 41.4832V42.1143C36.9703 42.7455 37.482 43.2571 38.1132 43.2571C38.7444 43.2571 39.256 42.7455 39.256 42.1143V41.4524C40.8281 41.2327 42 39.9397 42 38.3285V37.7856C42 36.0119 40.5552 34.5688 38.7792 34.5688Z"
        fill="#1787FC"
      />
      <path
        d="M31.2571 35.3143C31.8883 35.3143 32.4 34.8026 32.4 34.1714C32.4 33.5402 31.8883 33.0286 31.2571 33.0286H26.2857C25.6545 33.0286 25.1428 33.5402 25.1428 34.1714C25.1428 34.8026 25.6545 35.3143 26.2857 35.3143H31.2571Z"
        fill="#1787FC"
      />
      <path
        d="M22.2856 23.8857H21.7141C21.0829 23.8857 20.5713 24.3974 20.5713 25.0286C20.5713 25.6598 21.0829 26.1714 21.7141 26.1714H22.2856C22.9168 26.1714 23.4284 25.6598 23.4284 25.0286C23.4284 24.3974 22.9168 23.8857 22.2856 23.8857Z"
        fill="#1787FC"
      />
      <path
        d="M34.6857 29.6C34.6857 28.9688 34.174 28.4571 33.5428 28.4571H26.2857C25.6545 28.4571 25.1428 28.9688 25.1428 29.6C25.1428 30.2312 25.6545 30.7428 26.2857 30.7428H33.5428C34.174 30.7428 34.6857 30.2312 34.6857 29.6Z"
        fill="#1787FC"
      />
      <path
        d="M22.2856 19.3143H21.7141C21.0829 19.3143 20.5713 19.8259 20.5713 20.4571C20.5713 21.0883 21.0829 21.6 21.7141 21.6H22.2856C22.9168 21.6 23.4284 21.0883 23.4284 20.4571C23.4284 19.8259 22.9168 19.3143 22.2856 19.3143Z"
        fill="#1787FC"
      />
    </svg>
  );
}
