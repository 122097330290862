/**@jsx jsx */
import { jsx } from "theme-ui";

export function RightArrow() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 47 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M19.7419 42.1695C19.3361 42.5624 19.3361 43.2133 19.7419 43.6063L22.5512 46.3264C22.9389 46.7019 23.5547 46.7019 23.9424 46.3264L46.2581 24.7184C46.6639 24.3254 46.6639 23.6746 46.2581 23.2816L23.9424 1.67356C23.5547 1.29811 22.9389 1.29811 22.5512 1.67357L19.7419 4.39372C19.3361 4.78668 19.3361 5.43756 19.7419 5.83053L37.7645 23.2816C38.1703 23.6746 38.1703 24.3254 37.7645 24.7184L19.7419 42.1695Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="49"
            height="47"
            fill="white"
            transform="translate(0 49) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
