/**@jsx jsx */
import { jsx } from "theme-ui";

export function Phone() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.05 9C20.0267 9.19057 20.9244 9.66826 21.6281 10.3719C22.3317 11.0756 22.8094 11.9733 23 12.95L19.05 9ZM19.05 5C21.0793 5.22544 22.9716 6.13417 24.4162 7.57701C25.8609 9.01984 26.772 10.911 27 12.94L19.05 5ZM26 20.92V23.92C26.0011 24.1985 25.9441 24.4742 25.8325 24.7293C25.7209 24.9845 25.5573 25.2136 25.3521 25.4019C25.1469 25.5901 24.9046 25.7335 24.6407 25.8227C24.3769 25.9119 24.0974 25.9451 23.82 25.92C20.7428 25.5856 17.787 24.5341 15.19 22.85C12.7738 21.3147 10.7253 19.2662 9.18999 16.85C7.49997 14.2412 6.44824 11.271 6.11999 8.18C6.095 7.90347 6.12787 7.62476 6.21649 7.36162C6.30512 7.09849 6.44756 6.85669 6.63476 6.65162C6.82196 6.44655 7.0498 6.28271 7.30379 6.17052C7.55777 6.05833 7.83233 6.00026 8.10999 6H11.11C11.5953 5.99522 12.0658 6.16708 12.4338 6.48353C12.8017 6.79999 13.0421 7.23945 13.11 7.72C13.2366 8.68007 13.4714 9.62273 13.81 10.53C13.9445 10.8879 13.9737 11.2769 13.8939 11.6509C13.8141 12.0248 13.6289 12.3681 13.36 12.64L12.09 13.91C13.5135 16.4135 15.5864 18.4864 18.09 19.91L19.36 18.64C19.6319 18.3711 19.9751 18.1858 20.3491 18.1061C20.7231 18.0263 21.1121 18.0555 21.47 18.19C22.3773 18.5286 23.3199 18.7634 24.28 18.89C24.7658 18.9585 25.2094 19.2032 25.5265 19.5775C25.8437 19.9518 26.0122 20.4296 26 20.92Z"
        fill="currentcolor"
      />
    </svg>
  );
}
