/**@jsx jsx */
import { jsx } from "theme-ui";

export function Linkedin() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12C21.5913 12 23.1174 12.6321 24.2426 13.7574C25.3679 14.8826 26 16.4087 26 18V25H22V18C22 17.4696 21.7893 16.9609 21.4142 16.5858C21.0391 16.2107 20.5304 16 20 16C19.4696 16 18.9609 16.2107 18.5858 16.5858C18.2107 16.9609 18 17.4696 18 18V25H14V18C14 16.4087 14.6321 14.8826 15.7574 13.7574C16.8826 12.6321 18.4087 12 20 12Z"
        fill="currentcolor"
      />
      <path d="M10 13H6V25H10V13Z" fill="currentcolor" />
      <path
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        fill="currentcolor"
      />
    </svg>
  );
}
