/** @jsx jsx */
import { jsx } from "theme-ui";
import { useContext } from "react";
import { AppContext } from "../../context/app-context";
import { DrawerLinks } from "./drawer-links";

export function Drawer() {
  const { menuOpen } = useContext(AppContext);
  return (
    <div
      sx={{
        fontFamily: "heading",
        position: "fixed",
        display: ["grid", null, null, "none"],
        placeItems: "center",
        borderTop: "3px solid white",
        top: "64px",
        width: "100vw",
        height: "calc(100vh - 64px)",
        bg: "header",
        color: "background",
        padding: "16px",
        transition: "0.4s",
        opacity: menuOpen ? 1 : 0,
        transform: menuOpen ? "translateX(0)" : "translateX(-100%)",
        zIndex: 300,
        overflowY: "scroll",
      }}
    >
      <DrawerLinks />
    </div>
  );
}
