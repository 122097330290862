/**@jsx jsx */
import { jsx } from "theme-ui";

export function RightNavigation() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="23.9999"
        cy="24"
        r="22.6493"
        transform="rotate(-179.144 23.9999 24)"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="14.7808"
        y2="-1"
        transform="matrix(0.808876 -0.587979 0.790946 0.611887 21.2843 32.9323)"
        stroke="#767F97"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="14.7808"
        y2="-1"
        transform="matrix(-0.790946 -0.611887 0.808876 -0.587979 33.2402 24.2415)"
        stroke="#767F97"
        strokeWidth="2"
      />
    </svg>
  );
}
