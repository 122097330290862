/**@jsx jsx */
import { jsx } from "theme-ui";

export function Instagram() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 4H10C6.68629 4 4 6.68629 4 10V22C4 25.3137 6.68629 28 10 28H22C25.3137 28 28 25.3137 28 22V10C28 6.68629 25.3137 4 22 4Z"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4513 15.3399C20.5902 16.2762 20.4302 17.2324 19.9943 18.0726C19.5584 18.9127 18.8686 19.594 18.0232 20.0196C17.1777 20.4451 16.2196 20.5932 15.2851 20.4428C14.3506 20.2925 13.4873 19.8513 12.818 19.182C12.1487 18.5127 11.7075 17.6494 11.5572 16.7149C11.4068 15.7804 11.5549 14.8223 11.9804 13.9768C12.406 13.1314 13.0873 12.4416 13.9274 12.0057C14.7676 11.5698 15.7238 11.4098 16.6601 11.5487C17.6151 11.6903 18.4993 12.1353 19.182 12.818C19.8647 13.5007 20.3097 14.3849 20.4513 15.3399Z"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="22.75" cy="9.25" r="1" stroke="currentcolor" />
    </svg>
  );
}
