/**@jsx jsx */
import { jsx } from "theme-ui";

export function Website() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <g style={{ mixBlendMode: "multiply" }}>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29"
              fill="#1787FC"
            />
          </g>
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              d="M57 29C57 34.5379 55.3578 39.9514 52.2811 44.556C49.2045 49.1605 44.8315 52.7494 39.7151 54.8686C34.5988 56.9879 28.9689 57.5424 23.5375 56.462C18.106 55.3816 13.1169 52.7148 9.20102 48.799C5.28515 44.8831 2.61841 39.894 1.53802 34.4625C0.457639 29.031 1.01213 23.4012 3.13139 18.2849C5.25064 13.1685 8.83947 8.79553 13.444 5.71885C18.0486 2.64217 23.4621 1 29 1C32.6773 0.999173 36.3186 1.72285 39.7161 3.12969C43.1136 4.53653 46.2006 6.59895 48.8008 9.19916C51.401 11.7994 53.4635 14.8864 54.8703 18.2839C56.2772 21.6814 57.0008 25.3227 57 29V29Z"
              stroke="#1787FC"
              strokeWidth="1.21"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <path
        d="M52.8808 29.4467C52.8796 34.0825 51.5037 38.6138 48.9273 42.4677C46.3509 46.3217 42.6896 49.3251 38.4063 51.0983C34.123 52.8715 29.4101 53.3348 24.8636 52.4296C20.317 51.5244 16.1409 49.2915 12.8633 46.013C9.58575 42.7346 7.35389 38.5579 6.44995 34.0111C5.54601 29.4642 6.01057 24.7515 7.78491 20.4687C9.55924 16.1859 12.5637 12.5254 16.4183 9.94998C20.2729 7.37459 24.8046 6 29.4404 6C32.5192 6 35.5678 6.60651 38.4121 7.78489C41.2564 8.96327 43.8408 10.6904 46.0175 12.8678C48.1942 15.0451 49.9207 17.6299 51.0983 20.4745C52.2759 23.3191 52.8816 26.3679 52.8808 29.4467Z"
        fill="white"
      />
      <line
        x1="41.8427"
        y1="20.1818"
        x2="16.2273"
        y2="20.1818"
        stroke="#1787FC"
      />
      <rect
        x="26.2545"
        y="18.3092"
        width="12.7986"
        height="0.355263"
        rx="0.177632"
        fill="#495162"
        stroke="#1787FC"
        strokeWidth="0.355263"
      />
      <ellipse
        cx="21.5769"
        cy="18.4868"
        rx="0.346154"
        ry="0.355263"
        fill="#333844"
      />
      <ellipse
        cx="20.1676"
        cy="36.1231"
        rx="0.346154"
        ry="0.355263"
        fill="#333844"
      />
      <ellipse
        cx="35.5962"
        cy="38.0517"
        rx="0.346154"
        ry="0.355263"
        fill="#333844"
      />
      <ellipse
        cx="19.5001"
        cy="18.4868"
        rx="0.346154"
        ry="0.355263"
        fill="#333844"
      />
      <ellipse
        cx="17.4231"
        cy="18.4868"
        rx="0.346154"
        ry="0.355263"
        fill="#333844"
      />
      <rect
        x="15.5"
        y="16.5"
        width="26"
        height="26"
        rx="2.5"
        stroke="#1787FC"
      />
      <path
        d="M25.3062 25.3467L24.0342 28.3947H23.1402L22.5282 26.8827L21.9522 28.3947H21.0522L19.7802 25.3467H20.8842L21.5622 27.1047L22.2222 25.3467H22.9182L23.5962 27.1407L24.2622 25.3467H25.3062ZM30.7085 25.3467L29.4365 28.3947H28.5425L27.9305 26.8827L27.3545 28.3947H26.4545L25.1825 25.3467H26.2865L26.9645 27.1047L27.6245 25.3467H28.3205L28.9985 27.1407L29.6645 25.3467H30.7085ZM36.1109 25.3467L34.8389 28.3947H33.9449L33.3329 26.8827L32.7569 28.3947H31.8569L30.5849 25.3467H31.6889L32.3669 27.1047L33.0269 25.3467H33.7229L34.4009 27.1407L35.0669 25.3467H36.1109Z"
        fill="#1787FC"
      />
      <rect
        x="18.9615"
        y="22.8947"
        width="19.0769"
        height="7.52632"
        rx="1.5"
        stroke="#1787FC"
      />
      <rect
        x="17.9469"
        y="33.9408"
        width="13.4909"
        height="0.355263"
        rx="0.177632"
        fill="#333844"
        stroke="#1787FC"
        strokeWidth="0.355263"
      />
      <rect
        x="22.892"
        y="35.9455"
        width="14.8755"
        height="0.355263"
        rx="0.177632"
        fill="#333844"
        stroke="#1787FC"
        strokeWidth="0.355263"
      />
      <rect
        x="18.0706"
        y="38.3562"
        width="13.4909"
        height="0.355263"
        rx="0.177632"
        fill="#333844"
        stroke="#1787FC"
        strokeWidth="0.355263"
      />
      <rect
        x="33.1776"
        y="33.9408"
        width="5.87551"
        height="0.355263"
        rx="0.177632"
        fill="#333844"
        stroke="#1787FC"
        strokeWidth="0.355263"
      />
      <rect
        x="33.017"
        y="39.3205"
        width="5.87551"
        height="0.355263"
        rx="0.177632"
        fill="#333844"
        stroke="#1787FC"
        strokeWidth="0.355263"
      />
    </svg>
  );
}
