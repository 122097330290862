/**@jsx jsx */
import { jsx } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";
import { Phone, Mail, Map } from "../../../icons";

export function ContactLinks() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSocialLinks(filter: { node_locale: { eq: "es-PE" } }) {
        nodes {
          link
          title
          icon
        }
      }
    }
  `);
  const contactLinks = data.allContentfulSocialLinks.nodes;
  const locate = contactLinks.filter((link) => link.icon === "locate");
  const phone = contactLinks.filter((link) => link.icon === "phone");
  const mail = contactLinks.filter((link) => link.icon === "mail");

  return (
    <div
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 40px)",
        justifySelf: "flex-end",
      }}
    >
      <a
        rel="noopener"
        href={phone[0].link}
        sx={{ pr: "8px", color: "inherit" }}
        target="_blank"
      >
        <Phone />
        {<span sx={{ display: "none" }}>Llámanos</span>}
      </a>
      <a
        rel="noopener"
        href={mail[0].link}
        sx={{ pr: "8px", color: "inherit" }}
        target="_blank"
      >
        <Mail />
        {<span sx={{ display: "none" }}>Escríbenos</span>}
      </a>
      <a
        rel="noopener"
        href={locate[0].link}
        sx={{ color: "inherit" }}
        target="_blank"
      >
        <Map />
        {<span sx={{ display: "none" }}>Ubícanos</span>}
      </a>
    </div>
  );
}
