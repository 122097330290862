/**@jsx jsx */
import { jsx } from "theme-ui";

export function Dots() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 767.59 94.72"
      width="100%"
      height="100%"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <g sx={{ opacity: 0.7 }}>
            <rect
              fill="currentcolor"
              x="545.21"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="545.21"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="587.84"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="587.84"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="545.21"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M550.69,27.79h-5.48V25.05A2.74,2.74,0,0,1,548,22.31h0a2.74,2.74,0,0,1,2.74,2.74Z"
            />
            <path
              fill="currentcolor"
              d="M570.3,92a1,1,0,1,1-2.07,0,1,1,0,0,1,2.07,0m1.7,0a2.74,2.74,0,0,0-5.48,0h0A2.74,2.74,0,1,0,572,92Z"
            />
            <path
              fill="currentcolor"
              d="M570.3,69.67a1,1,0,1,1-2.07,0,1,1,0,0,1,2.07,0m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="566.52"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="523.36"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="523.36"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M527.14,47.36a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="523.36"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M505.28,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1M507,92a2.74,2.74,0,0,0-5.48,0h0A2.74,2.74,0,0,0,507,92Z"
            />
            <path
              fill="currentcolor"
              d="M505.28,69.67a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.71,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="479.66"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="479.66"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M483.43,47.36a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,1,0-5.47,0h0a2.74,2.74,0,1,0,5.47,0Z"
            />
            <path
              fill="currentcolor"
              d="M485.13,27.79h-5.47V25.05a2.74,2.74,0,0,1,2.74-2.74h0a2.74,2.74,0,0,1,2.73,2.74Z"
            />
            <path
              fill="currentcolor"
              d="M461.58,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,1,0-5.47,0h0a2.74,2.74,0,1,0,5.47,0Z"
            />
            <path
              fill="currentcolor"
              d="M461.58,69.67a1,1,0,1,1-2.07,0,1,1,0,0,1,2.07,0m1.7,0a2.74,2.74,0,1,0-5.47,0h0a2.74,2.74,0,1,0,5.47,0Z"
            />
            <rect
              fill="currentcolor"
              x="435.95"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="435.95"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="435.95"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="435.95"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M439.73,2.74a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="414.1"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="414.1"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M417.88,47.36a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="414.1"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M396,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="740.8"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M765.89,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="718.95"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M700.87,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="675.24"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M657.17,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="631.54"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="609.69"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M396,69.67a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="370.4"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M374.17,69.67a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.71,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="370.4"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="370.4"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M375.88,5.48H370.4V2.74A2.74,2.74,0,0,1,373.14,0h0a2.74,2.74,0,0,1,2.74,2.74Z"
            />
            <path
              fill="currentcolor"
              d="M352.32,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1M354,92a2.74,2.74,0,1,0-5.48,0h0A2.74,2.74,0,1,0,354,92Z"
            />
            <rect
              fill="currentcolor"
              x="348.55"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M352.32,25.05a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.71,0a2.74,2.74,0,1,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect fill="currentcolor" x="348.55" width="5.48" height="5.48" />
            <rect
              fill="currentcolor"
              x="326.7"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="326.7"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M330.47,47.36a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,1,0-5.47,0h0a2.74,2.74,0,1,0,5.47,0Z"
            />
            <rect
              fill="currentcolor"
              x="326.7"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="304.84"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="304.84"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="304.84"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="304.84"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M308.62,2.74a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="42.63"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="42.63"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <rect fill="currentcolor" y="89.24" width="5.48" height="5.48" />
            <rect fill="currentcolor" y="66.93" width="5.48" height="5.48" />
            <path
              fill="currentcolor"
              d="M25.09,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,1,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <path
              fill="currentcolor"
              d="M25.09,69.67a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="21.31"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="64.48"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="64.48"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M90.1,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.71,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <path
              fill="currentcolor"
              d="M90.1,69.67a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.71,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="108.18"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="108.18"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M112,47.36a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,1,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <path
              fill="currentcolor"
              d="M108.18,27.79h5.48V25.05a2.74,2.74,0,0,0-2.74-2.74h0a2.74,2.74,0,0,0-2.74,2.74Z"
            />
            <path
              fill="currentcolor"
              d="M133.81,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0A2.74,2.74,0,1,0,130,92h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <path
              fill="currentcolor"
              d="M133.81,69.67a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,1,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="151.88"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="151.88"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="151.88"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="151.88"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M155.66,2.74a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="173.74"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="173.74"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M177.51,47.36a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,1,0-5.47,0h0a2.74,2.74,0,1,0,5.47,0Z"
            />
            <rect
              fill="currentcolor"
              x="173.74"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M199.36,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,1,0-5.47,0h0a2.74,2.74,0,1,0,5.47,0Z"
            />
            <path
              fill="currentcolor"
              d="M199.36,69.67a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,1,0-5.47,0h0a2.74,2.74,0,1,0,5.47,0Z"
            />
            <rect
              fill="currentcolor"
              x="217.44"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M221.21,69.67a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.71,0a2.74,2.74,0,1,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="217.44"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="217.44"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M217.44,5.48h5.48V2.74A2.74,2.74,0,0,0,220.18,0h0a2.74,2.74,0,0,0-2.74,2.74Z"
            />
            <path
              fill="currentcolor"
              d="M243.07,92a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,1,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="239.29"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M243.07,25.05a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,0,0,5.48,0Z"
            />
            <rect fill="currentcolor" x="239.29" width="5.48" height="5.48" />
            <rect
              fill="currentcolor"
              x="261.14"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="261.14"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M264.92,47.36a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
            <rect
              fill="currentcolor"
              x="261.14"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="282.99"
              y="89.24"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="282.99"
              y="66.93"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="282.99"
              y="44.62"
              width="5.48"
              height="5.48"
            />
            <rect
              fill="currentcolor"
              x="282.99"
              y="22.31"
              width="5.48"
              height="5.48"
            />
            <path
              fill="currentcolor"
              d="M286.77,2.74a1,1,0,1,1-1-1,1,1,0,0,1,1,1m1.7,0a2.74,2.74,0,0,0-5.48,0h0a2.74,2.74,0,1,0,5.48,0Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
{
  /* <style>.cls-1{opacity:0.7;}.cls-2{fill:#1787fc;}</style> */
}
