/**@jsx jsx */
import { jsx } from "theme-ui";

export function Mail() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 8H8C7.44881 8 6.94783 8.22598 6.58514 8.58986C6.92377 8.4358 7.33525 8.47767 7.64018 8.73178L16 15.6983L24.3598 8.73178C24.6647 8.47767 25.0762 8.4358 25.4149 8.58986C25.0522 8.22598 24.5512 8 24 8ZM25.9784 9.70685C25.9337 9.91917 25.8198 10.1185 25.6402 10.2682L16.6402 17.7682C16.2693 18.0773 15.7307 18.0773 15.3598 17.7682L6.35982 10.2682C6.18018 10.1185 6.06633 9.91917 6.02158 9.70685C6.00737 9.80258 6 9.90047 6 10V22C6 23.1 6.9 24 8 24H24C25.1 24 26 23.1 26 22V10C26 9.90047 25.9926 9.80258 25.9784 9.70685Z"
        fill="currentcolor"
      />
    </svg>
  );
}
