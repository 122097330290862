/**@jsx jsx */
import { jsx } from "theme-ui";

export function DownArrow() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 49 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83053 15.7419C6.43756 15.3361 5.78668 15.3361 5.39372 15.7419L2.67357 18.5512C2.29811 18.9389 2.29811 19.5547 2.67357 19.9424L24.2816 42.2581C24.6746 42.6639 25.3254 42.6639 25.7184 42.2581L47.3264 19.9424C47.7019 19.5547 47.7019 18.9389 47.3264 18.5512L44.6063 15.7419C44.2133 15.3361 43.5624 15.3361 43.1695 15.7419L25.7184 33.7645C25.3254 34.1703 24.6746 34.1703 24.2816 33.7645L6.83053 15.7419Z"
        fill="currentcolor"
      />
    </svg>
  );
}
