/**@jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import { jsx } from "theme-ui";
import { Facebook, Instagram, Linkedin } from "../../../icons";

export function SocialLinks() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSocialLinks(filter: { node_locale: { eq: "es-PE" } }) {
        nodes {
          link
          title
          icon
        }
      }
    }
  `);
  const socialLinks = data.allContentfulSocialLinks.nodes;

  const facebook = socialLinks.filter((link) => link.icon === "facebook");
  const instagram = socialLinks.filter((link) => link.icon === "instagram");
  const linkedin = socialLinks.filter((link) => link.icon === "linkedin");

  return (
    <div
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 40px)",
        justifySelf: "flex-start",
      }}
    >
      <a
        rel="noopener"
        href={facebook[0].link}
        target="__blank"
        sx={{ pr: 2, color: "inherit" }}
      >
        <span sx={{ display: "none" }}>Facebook Praxisnet</span>

        <Facebook />
      </a>
      <a
        rel="noopener"
        href={instagram[0].link}
        target="__blank"
        sx={{ pr: 2, color: "inherit" }}
      >
        <span sx={{ display: "none" }}>Instagram Praxisnet</span>

        <Instagram />
      </a>
      <a
        rel="noopener"
        href={linkedin[0].link}
        target="__blank"
        sx={{ color: "inherit" }}
      >
        <span sx={{ display: "none" }}>Linkedin Praxisnet</span>

        <Linkedin />
      </a>
    </div>
  );
}
