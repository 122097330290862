/**@jsx jsx */
import { jsx } from "theme-ui";

export function Map() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 27C16 27 25 21 25 14C25 11.6131 24.0518 9.32387 22.364 7.63604C20.6761 5.94821 18.3869 5 16 5C13.6131 5 11.3239 5.94821 9.63604 7.63604C7.94821 9.32387 7 11.6131 7 14C7 21 16 27 16 27ZM19 14C19 15.6569 17.6569 17 16 17C14.3431 17 13 15.6569 13 14C13 12.3431 14.3431 11 16 11C17.6569 11 19 12.3431 19 14Z"
        fill="currentcolor"
      />
    </svg>
  );
}
