/**@jsx jsx  */
import { jsx, Styled } from "theme-ui";
import { Global, css } from "@emotion/core";
import { useLocation } from "@reach/router";

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";

import { Footer } from "../common/footer/footer";
import { Drawer } from "../navigation/drawer";
import { Header } from "../common/header/header";

export function Layout({ children }) {
  const [chat, setChat] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (window && window.innerWidth > 600 && location.href === "/") {
      setChat(true);
    }
  }, []);

  return (
    <Styled.root
      sx={{
        fontFamily: "body",
        textAlign: "justify",
        overflowX: "hidden",
      }}
    >
      {chat && (
        <Helmet>
          <script
            key={1}
            src="//code.tidio.co/dnebdwbfk1ihmw434iqbctxqlz634ltk.js"
            defer
          ></script>
        </Helmet>
      )}
      <Header />
      <Global styles={styles} />
      <Drawer />

      <div
        sx={{
          mt: "64px",
          minHeight: ["calc(100vh - 128px)", null, null, "calc(100vh - 176px)"],
          overflow: "hidden",
        }}
      >
        {children}
      </div>
      <Footer />
    </Styled.root>
  );
}
const styles = css`
  @font-face {
    font-family: "Mulish";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v1/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSzuSfFpQ6HTY.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Mulish";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v1/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSzuSfF5Q6HTY.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Mulish";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v1/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSzuSfGZQ6.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v1/1Ptvg83HX_SGhgqk0AotcqA.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v1/1Ptvg83HX_SGhgqk0QotcqA.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v1/1Ptvg83HX_SGhgqk3wot.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v1/1Ptvg83HX_SGhgqk0AotcqA.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v1/1Ptvg83HX_SGhgqk0QotcqA.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v1/1Ptvg83HX_SGhgqk3wot.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  #tidio-chat {
    z-index: 200 !important;
    position: relative;
  }
  #netlify-identity-widget {
    z-index: 600 !important;
  }
`;
